.architecture {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-column-gap: 100px;
    //display: flex;
    padding-top: 100px;
    padding-bottom: 16vh;
    position: relative;
    &__content {
        max-width: 1100px;
        h2 {
            font-size: rem(26px);
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 50px;
        }
        p {
            line-height: rem(38px);
        }
    }
    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: 50px;
        margin-top: 70px;
        &-single {
            display: flex;
            column-gap: 20px;
            align-items: center;
            img {
                max-width: 100px;
                padding: 15px;
                border: 1px solid $color-extra;
                aspect-ratio: 1;
                transition: ease 0.3s all;
            }
            h4 {
                font-size: rem(18px);
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            p {
                line-height: rem(30px);
            }
            &:hover {
                img {
                    background-color: $color-extra;
                }
            }
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: $color-main;
        padding-left: 60px;
        &::after {
            content: '';
            position: absolute;
            background: $color-main;
            width: calc(8vw + 300px);
            height: 100vh;
            top: -200px;
            right: 0;
            z-index: 0;
        }      
        &::before {
            content: '';
            position: absolute;
            background: $color-main;
            width: calc(8vw + 300px);
            height: 100vh;
            bottom: -200px;
            right: 0;
            z-index: 0;
        }      
        &-single {
            position: relative;
            z-index: 1;
            h3 {
                font-size: rem(42px);
                font-weight: 700;
                text-transform: uppercase;
                color: $color-extra;
                margin-bottom: 10px;
            }
            p, span {
                font-size: rem(22px);
                font-weight: 300;
                color: $color-extra;
            }
        }
    }
}
@include mq($until: xl){
    .architecture {
        &__info {
            justify-content: space-evenly;
            &:after {
                width: 400px;
                height: 100%;
                top: -40vh;
            }
            &:before {
                width: 400px;
            }
            h3 {
                font-size: rem(28px);
            }
            p {
                font-size: rem(20px);
            }
        }
        &__grid {
            grid-template-columns:  1fr;
        }
    }
}
@include mq($until: lg){
    .architecture {
        grid-column-gap: 50px;
        &__info {
            justify-content: space-evenly;
            &:after {
                width: 350px;
                height: 100%;
            }
            &:before {
                width: 350px;
            }
        }
    }
}
@include mq($until: md){
    .architecture {
        padding-top: 0;
        padding-bottom: 80px;
        grid-template-columns: 1fr;
        &__content {
            h2, p {
                text-align: center;
            }
        }
        &__grid p {
            text-align: left;
        }
        &__info {
            position: relative;
            margin-top: 80px;
            padding-top: 40px;
            padding-left: 0;
            padding-bottom: 40px;
            row-gap: 40px;
            &:after {
                content: '';
                width: 100vw;
                height: 100%;
                left: -50px;
                top: 0;
            }    
            &:before {
                display: none;
            }
            &-single {
                &:nth-child(2) p:first-of-type {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
@include mq($until: sm){
    .architecture {
        &__info {
            &:after {
                left: -30px;
            }    
        }
    }
}
@include mq($until: xs){
    .architecture {
        &__grid img {
            max-width: 80px;
            height: auto;
        }
        &__info {
            &:after {
                left: -20px;
            }    
        }
    }
}
