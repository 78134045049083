.flat-list-page{
  &__front-image{
    img{
      display: block;
      width: 100%;
    }
  }
  &__main-text{
    margin: 100px 0 200px 0;
    @include mq($until: md) {
      margin: 50px 0 100px 0;
    }
    p{
      font-size: rem(20px);
    }
  }
}

.flat-list {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    @include mq($until: md) {
      margin-bottom: 80px;
    }
  }
  &__text {
    background-color: $color-main;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    padding-right: 60px;
    height: 90px;
    margin-top: -89px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    @include mq($until: md) {
     margin-top: 0;
    }
    @include mq($until: sm) {
      flex-direction: column;
      justify-content: center;
    }
    h3 {
      margin: 0;
      @include mq($until: sm) {
        text-align: center;
      }
      span {
        color: $color-font-light;
        font-size: rem(26px);
        font-weight: 700;
        text-transform: uppercase;
        display: inline-block;
        line-height: 0;
      }
    }
    p {
      opacity: 0.5;
      text-align: right;
      line-height: 22px;
      span {
        color: $color-font-light;
        font-style: italic;
        font-weight: 300;
        display: inline-block;
        line-height: 0;
      }
    }
  }
  &__head {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 3fr 1fr;
    justify-content: stretch;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 60px;
    background-color: $color-main;
    grid-gap: 5px;
    @include mq($until: xl) {
      grid-template-columns: 1fr 2fr 2fr 2fr 3fr 1fr;
    }
    @include mq($until: lg) {
      grid-template-columns: 1fr 2fr 2fr 2fr 3fr 1fr;
      grid-gap: 5px;
    }
    @include mq($until: md) {
      display: none;
    }
    position: relative;
    span {
      color: #fff;
      //font-size: rem(18px);
    }

  }
  &__head-item {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    align-items: center;
    text-align: center;
    font-weight: $fw-medium;
    font-size: rem(20px);
    color: $color-font-light;
    &:first-of-type {
      text-align: left;
      justify-content: flex-start;
    }
    &--action{
      cursor: pointer;
      transition: color .3s;
      &:hover{
        color: $color-extra
      }
    }
    &.active{
      color: $color-extra
    }
    i{
      margin-right: 5px;
      padding-bottom: 9px;
    }
    &[data-order="asc"]{
      i{
        transform: scale(1);
      }
    }
    &[data-order="desc"]{
      i{
        transform: scale(-1);
      }    
    }
    @include mq($until: xxl) {
      font-size: rem(16px);
    }

    @include mq($until: xl) {
      font-size: rem(13px);
    }
    
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
  }
  &__item {
    display: grid;
    padding-right: 60px;
    grid-template-columns: 1fr 2fr 2fr 2fr 3fr 1fr;
    justify-content: stretch;
    align-items: center;
    transition: 0.3s;
    position: relative;
    background-color: #fff;
    border-bottom: 1px solid #EAEAEC;
    &:hover, &.active {
      background: #F7F7F7;
    }
    &:last-child {
      margin-bottom: 0;
      border: 0;
    }
    @include mq($until: xl) {
      grid-template-columns: 1fr 2fr 2fr 2fr 3fr 1fr;
    }
    @include mq($until: lg) {
      grid-template-columns: 1fr 2fr 2fr 2fr 3fr 1fr;
      
    }
    @include mq($until: md) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      grid-gap: 0;
      padding: 50px;
    }
    @include mq($until: sm) {
      padding: 30px;
    }
    @include mq($until: xs) {
      padding: 20px;
    }
  }
  &__status {
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin-right: 5px;
  }
  &__info {
    display: flex;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    font-size: rem(16px);
    &:first-of-type {
      text-align: left;
      justify-content: flex-start;
    }
    @include mq($until: lg) {
      font-size: rem(14px);
    }
    @include mq($until: md) {
      justify-content: space-between;
      font-weight: $fw-bold;
      font-size: rem(18px);
      padding: 0;
      flex-basis: 100%;
      margin-bottom: 5px;
      &:last-child{
        margin-left: auto;
      }
      &:first-of-type {
        justify-content: space-between;
      }
    }
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
    &--button{
      @include mq($until: md) {
        .flat-list__item-value{
          button, a, span{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0;
            justify-content: flex-end;
          }
        }
      }
    }

  }
  &__item-value{
    position: relative;
  }
  &__item-label {
    display: none;
    @include mq($until: md) {
      display: block;
      margin-right: 10px;
      font-weight: $fw-regular;
      font-size: rem(20px);
      display: inline;
    }
  }
  &__item-value {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(16px);
    @include mq($until: lg) {
      font-size: rem(14px);
    }
    @include mq($until: md) {
      font-size: rem(18px);
      font-weight: $fw-bold;
      display: inline;
    }
    span:first-letter {
      text-transform: uppercase;
    }
    span,
    a {
      font-weight: 500;
      font-size: rem(20px);
      margin-left: 5px;
      white-space: nowrap;
      @include mq($until: xxl) {
        font-size: rem(14px);
      }
      @include mq($until: md) {
        font-size: rem(18px);
      }
    }
    svg {
      height: 20px;
      fill: $color-main;
      transition: 0.2s;
      margin-right: 5px;
      @include mq($until: xl) {
        height: 21px;
      }
    }
    a,
    button {
      padding: 5px 10px;
      transition: 0.2s;
      white-space: nowrap;

      &:hover {
        background-color: $color-extra;
        svg {
          fill: $color-font-light;
          @include mq($until: md) {
            // width: 10px
          }
        }
      }
      @include mq($until: md) {
        padding: 5px 8px;
        font-size: rem(16px);
        font-weight: $fw-semi-bold;
      }
    }
  }

  &__no-results {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-size: rem(26px);
    color: $border-color;
  }

}



