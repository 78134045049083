.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  padding: 40px;
}

.modal-body {
  padding: 0 !important;
}

.modal-content button.close {
  text-shadow: none;
  outline: none !important;
  top: 20px;
  right: 20px;

  position: absolute;
  border: transparent;
  border-radius: 100%;
  background: #e9e8e8;
  color: $color-main;
  cursor: pointer;
  font-size: 20px;
  transition: 0.2s;
  padding: 10px;
  &:hover {
    background-color: $color-extra;
  }
  svg {
    line {
      stroke: $color-main;
    }
  }
}
