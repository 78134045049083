.features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 100px;
    grid-row-gap: 40px;    
    padding-top: 100px;
    padding-bottom: 100px;
    justify-items: center;
    position: relative;
    overflow: hidden;
    &__content {
        max-width: 520px;
        width: 100%;
        &:first-of-type:after {
            content: '';
            width: 1px;
            height: 100%;
            position:absolute;
            left: 50%;
            top: 0;
            background: $color-extra;
        }
        &:last-of-type {
            display: flex;
            flex-direction: column;
            justify-content: space-between;        
        }
    }
    h2 {
        font-size: rem(26px);
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    h3 {
        font-size: rem(18px);
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 50px;
    }
    p {
        line-height: rem(34px);
    }
    .block {
        margin-bottom: 40px;
    }
    .quote {
        margin-bottom: 60px;
        padding: 40px;
        position: relative;
        h3 {
            margin-bottom: 30px;
        }
        p {
            font-style: italic;
        }
        em {
            margin-top: 20px;
            display: block;
            font-weight: 600;
        }
        &:after {
            content: '';
            width: 50vw;
            height: 100%;
            position:absolute;
            top: 0;
            left: 0;
            //background-color: $color-extra;
            z-index: -1;
            border: 1px solid $color-extra;
            background: #fff;
            box-shadow: -20px 20px #e3d3b9;
        }
    }
    .contact p {
        margin-bottom: 30px;
    }
    a {
        font-size: rem(18px);
        font-weight: 600;
        font-style: italic;
        margin-top: 50px;

        display: block;
        max-width: 450px;
        padding-right: 60px;
        position: relative;
        span {
            padding-top: 10px;
            padding-bottom: 10px;
            span {
                border-bottom: 1px solid $color-extra;
                display: inline-block;
            }
        }
        svg {
            transform: rotate(90deg);
            stroke: $color-main;
            border-radius: 100%;
            background: rgba(227, 211, 185, 0.5);
            //border: 1px solid $color-extra;
            transition: ease 0.3s all;
            width: 45px;
            height: 45px;
            padding: 10px;
            margin-left: 20px;

            position: absolute;
            right: 0;
            top: 50%;
            transform: rotate(90deg) translateX(-50%);
        }
        &:hover {
            svg {
                background: $color-extra;
            }
        }
    }
    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 50px;
        grid-row-gap: 50px;
        margin-top: 70px;
        &-single {
            display: flex;
            column-gap: 20px;
            align-items: center;
            img {
                max-width: 110px;
                padding: 15px;
                border: 1px solid $color-extra;
                aspect-ratio: 1;
                transition: ease 0.3s all;
            }
            h4 {
                font-size: rem(18px);
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            p {
                line-height: rem(30px);
            }
            &:hover {
                img {
                    background-color: $color-extra;
                }
            }
        }
    }
}

@include mq($until: md){
    .features {
        grid-template-columns: 1fr;
        grid-row-gap: 0;
        padding-top: 80px;
        &__content {
            max-width: 100%;
            border-bottom: 1px solid $color-extra;
            margin-bottom: 80px;
            padding-bottom: 80px;
            &:last-of-type {
                border: 0;
                padding-bottom: 0;
                margin-bottom: 0;
            }
            &::after {
                display: none;
            }
            h2, p {
                text-align: center;
            }
            h3 {
                font-size: rem(26px);
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 40px;
                text-align: center;
            }
            .block {
                margin-bottom: 80px;
            }
            .quote {
                margin-bottom: 80px;
                text-align: center;
                &:after {
                    width: 100%;
                    box-shadow: none;
                }
            }
            .contact {
                text-align: center;
                a {
                    margin-left: auto;
                    margin-right: auto;                
                }
            }
        }
        &__grid p {
            text-align: left;
        }
    }
}
@include mq($until: xs){
    .features {
        &__content .quote {
            padding-left: 20px;
            padding-right: 20px;
        }
        &__grid img {
            max-width: 80px;
            height: auto;
        }
        a {
            position: relative;
            display: inline-block;
            margin-top: 0;
            padding-right: 0;
            span {
                display: block;
            }
            svg {
                margin-left: 0;
                margin-top: 10px;
                position: relative;
                transform: translateX(0);
                g {
                    transform: rotate(90deg) translate(6.704px, -23.296px);
                }
            }
        }
    }
}
