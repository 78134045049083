.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 130px;
  background: $color-main;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    width: 200px;
    img {
      height: auto;
    }
  }
  &__content {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }
  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    padding: 0;
    margin: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #fff;
    font-size: rem(20px);
    font-weight: 500;
    padding: 10px 15px;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-extra;
    }
  }

  &__contact {
    background: $color-extra;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    margin-right: -5vw;
    a {
      padding: 10px;
      display: flex;
      align-items: center;
    }
    img {
      margin: 0 15px;
    }
    a, span {
      font-weight: 300;
      font-style: italic;
    }
    .email {
      border: 1px solid rgba(45, 56, 74, 0.2);
      transition: ease 0.3s all;
      width: 44px;
      height: 44px;
      span {
        display: none;
      }
      img {
        margin: 0;
      }
      &:hover {
        border-color: $color-main;
      }
    }
  }
}
@include mq($until: xl) {
  .header {
    &__contact {
      margin-right: -72px;
      a:first-of-type span:first-of-type {
        display: none;
      }
    }
  }
}
@include mq($until: lg) {
  .header {
    &__logo {
      width: 150px;
    }
    &__contact {
      margin-right: 0;
      a {
        border: 1px solid rgba(45, 56, 74, 0.2);
        transition: ease 0.3s all;
        width: 44px;
        height: 44px;
        &:hover {
          border-color: $color-main;
        }     
      }
      img {
        margin: 0;
      }
      .email {
        margin-left: 10px;
      }
      span {
        display: none;
      }
    }
  }
}
@include mq($until: md) {
  .header {
    &__content {
      justify-content: flex-end;
    }
    &__contact {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 10px;
      text-align: center;
      z-index: 9999;
      justify-content: center;
      height: 70px;
      a, a.email {
        width: auto;
      }
      span, .email span {
        display: inline;
      }
      a img, a.email img {
        margin-right: 10px;
      }
    }
  }
}
@include mq($until: 320px) {
  .header__contact a.email {
    span {
      display: none;
    }
    img {
      margin: 0;
    }
  }
}
