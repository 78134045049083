.location {
    &__map {
        position: relative;
    }
    &__insert {
        min-height: 80vh;
    }
    .leaflet-tile-pane {
        filter: grayscale(1);
    }    
    &__content {
        display: grid;
        grid-template-columns: 1fr 300px;
        grid-column-gap: 100px;
        padding-top: 70px;
        padding-bottom: 130px;
    }
    &__text {
        padding-bottom: 100px;
        max-width: 720px;
        h2 {
            font-size: rem(26px);
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 50px;
            span {
                font-size: rem(26px);
                font-weight: 300;
                margin-left: 20px;
            }
        }
        p {
            line-height: rem(38px);
        }
    }
    &__info {
        display: flex;
        align-items: center;
        padding-left: 60px;
        border-left: 1px solid $color-extra;
        h3 {
            font-size: rem(42px);
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 100px;
        }
    }
}
@include mq($until: 1330px){
    .location__text h2 span {
        display: block;
        margin-left: 0;
        margin-top: 20px;
    }
}

@include mq($until: xl){
    .location h3 {
        font-size: rem(28px);
    }
}

@include mq($until: md){
    .location {
        &__content {
            grid-template-columns: 1fr;
            padding-bottom: 80px;
        }
        &__text {
            max-width: 100%;
            text-align: center;
            padding-bottom: 80px;
        }
        &__info {
            padding: 40px;
            border: 1px solid $color-extra;
            display: block;
            h3 {
                margin-bottom: 0;
                text-align: center;
            }
        }
    }
}
