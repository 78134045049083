.project {
    position: relative;
    &__content {
        display: grid;
        grid-template-columns: 1fr 750px;
        grid-column-gap: 0;
        padding-top: 130px;
        padding-bottom: 130px;
    }
    &__text {
        padding-bottom: 100px;
        max-width: 720px;
        h2 {
            font-size: rem(26px);
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 100px;
        }
        p {
            line-height: rem(38px);
            border-left: 1px solid $color-extra;
            padding: 80px 0 80px 80px;
            margin-left: 40px;
        }
    }
    &__view {
        //padding-left: 60px;
        h3 {
            font-size: rem(26px);
            font-weight: 300;
            margin-bottom: 100px;
            color: #fff;
            position: absolute;
            right: 0;
            width: 300px;
            padding-left: 60px;
        }
        &:before {
            content: '';
            position: absolute;
            background: #2D384A;
            width: calc(8vw + 300px);
            height: 100vh;
            min-height: 100%;
            top: 0px;
            right: 0;
            z-index: -1;
        }
        .view {
            /*position: absolute;
            top: 100px;
            right: 8vw;*/
            max-width: 800px;
            width: 100%;
        }
        .slick-arrow {
            position: absolute;
            right: 0;
            bottom: 100px;
            border-radius: 100%;
            background: rgba(227, 211, 185, 0.2);
            transition: ease 0.3s all;
            width: 4vw;
            height: 4vw;
            padding: 15px;
            z-index: 9;
            &:first-of-type {
                transform: rotate(180deg);
                right: 4vw;
                margin-right: 20px;
            }
            &:last-of-type {
                right: 0;
            }
            &:hover {
                background: #E3D3B9;
                stroke: #2D384A;
                cursor: pointer;
            }
            
            
        }
    }
}
/*
@include mq($until: 1680px){
    .project {
        &__view .view {
            max-width: 700px;
            margin-left: auto;
        }
    }
}
@include mq($until: 1580px){
    .project {
        &__view .view {
            max-width: 600px;
        }
    }
}*/
@include mq($until: 1680px){
    .project {
        &__text {
            p {
                margin-left: 0;
            }
        }
    }
}

@include mq($until: xl){
    .project {
        &__content {
            grid-template-columns: 1fr 50%;
        }
        &__text {
            p {
                margin-left: 0;
            }
        }
        &__view {
            &:before {
                width: 400px;
            }
            h3 {
                font-size: rem(28px);
            }
            p {
                font-size: rem(20px);
            }
            .slick-arrow {
                width: 50px;
                height: 50px;
                &:first-of-type {
                    right: 40px;
                }
            }
        }
    }
}
@include mq($until: lg){
    .project {
        grid-column-gap: 50px;
        &__view {
            &:before {
                width: 350px;
            }
        }
    }
}
@include mq($until: md){
    .project {
        padding-left: 0;
        padding-right: 0;
        &__content {
            display: flex;
            flex-direction: column-reverse;
            padding-top: 0;
            padding-bottom: 80px;
            grid-template-columns: 1fr;
        }
        &__text {
            max-width: 100%;
            margin-top: 80px;
            padding-bottom: 0;
            padding-left: 50px;
            padding-right: 50px;
            h2 {
                text-align: center;
                margin-bottom: 20px;
            }
            p {
                text-align: center;
                padding: 0;
                border: 0;
            }
        }
        &__view {
            width: 100%;
            background: $color-main;
            padding: 0 50px 40px 50px;
            &:before {
                display: none;
            }
            h3 {
                margin-top: 80px;
                width: 100%;
                text-align: center;
                position: relative;
                margin-bottom: 0px;
                padding: 0;
            }
            .view {
                display: block;
                margin: 0 auto;
            }
            .slick-arrow {
                bottom: 40px;
            }
        }
    }
}

@include mq($until: sm){
    .project {
        &__text {
            padding-left: 30px;
            padding-right: 30px;
        }
        &__view {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}
@include mq($until: xs){
    .project {
        &__text {
            padding-left: 20px;
            padding-right: 20px;
        }
        &__view {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
