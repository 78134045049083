.footer {
  background-color: $color-main;
  margin-top: 100px;
  &__image {
    position: relative;
    z-index: 0;
    .image {
      width: 100%;
      height:80vh;
      background-position: center;
      background-size: cover;
      /*background-position: 50% 0;
      background-size: cover;
      background-attachment: fixed;*/
    }
  }
  &__contact {
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    z-index: 1;
    .left {
      white-space: nowrap;
    }
    .logo img {
      margin-bottom: 40px;
      height: auto;
    }
    p {
      color: #fff;
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    form {
      margin-top: -200px;
    }
    .info {
      display: flex;
      justify-content: space-between;
      column-gap: 20px;
      row-gap: 20px;
      a, span {
        color: #fff;
        transition: ease 0.3s color;
      }
      .call {
        &:hover, &:hover span {
          color: $color-extra;
        }
        img {
          margin: 0 10px;
        }
      }
      .social {
        span {
          opacity: 0.5;
          margin-right: 10px;
        }
        a {
          opacity: 0.5;
          transition: ease 0.3s all;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
}


@include mq($until: md){
  .footer {
    margin-top: 0;
    &__contact {
      flex-direction: column;
      .right {
        margin-top: 50px;
        display: flex;
        flex-direction: column-reverse;    
      }
      .form {
        margin-top: 50px;
        margin-bottom: 0;
        max-width: 100%;
      }
    }
    &__image .image {
      aspect-ratio: 4/3;
      height: auto;
    }
    &-small {
      margin-bottom: 70px;
    }
  }
}

@include mq($until: sm){
  .footer {
    &-small {
      flex-direction: column;
      row-gap: 10px;
    }
  }
}

@include mq($until: xs){
  .footer {
    &__contact {
      .info {
        flex-direction: column;
        .social span {
          opacity: 1;
        }
      }
    }
  }
}
