.hero {
    background: $color-main;
    min-height: calc(100vh - 130px);
    display: flex;
    flex-direction: column;
    &::after {
        content: '';
        position: absolute;
        background: $color-extra;
        width: calc(8vw + 300px);
        height: 33vh;
        top: 0;
        right: 0;
        z-index: 0;
    }
    &__wrapper {
        position: relative;
        z-index: 2;
        background: $color-main;
    
    }
    &__slider {
        position: relative;
        z-index: 2;
        background: $color-main;
        * {
            font-size: 0;
        }
        .image {
            width: 100%;
            min-height: calc(100vh - 130px);
            background-position: center;
            background-size: cover;
        }
    }

    &__top {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex-grow: 1;
        padding-top: 130px;
        padding-right: 17vw;
        h1 {
            color: #fff;
            font-size: rem(45px);
            font-weight: 700;
            text-transform: uppercase;
            text-shadow: 0 3px 16px rgba(0, 0, 0, 0.16), 2px 1px 0px rgba(46, 55, 74, 0.36);
            margin: 0;
        }
        h2 {
            color: #fff;
            font-size: rem(30px);
            font-weight: 400;
            text-shadow: 0 3px 16px rgba(0, 0, 0, 0.16), 2px 1px 0px rgba(46, 55, 74, 0.36);
            margin: 20px 0 0 0;
        }
    }
    &__bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex-grow: 1;
        padding-top: 130px;
        padding-right: 17vw;
        a {
            position: absolute;
            bottom: 40px;
            right: 0;
            display: flex;
            align-items: center;
            span {
                color: #fff;
                font-size: rem(24px);
                font-weight: 700;
                text-transform: uppercase;
                text-shadow: 0 3px 16px rgba(0, 0, 0, 0.16), 2px 1px 0px rgba(46, 55, 74, 0.36);
                transition: ease 0.3s all;
            }
            svg {
                border-radius: 100%;
                background: rgba(227, 211, 185, 0.20);
                transition: ease 0.3s all;
                width: 4vw;
                height: 4vw;
                padding: 15px;
                margin-left: 4vw;
                margin-right: 2vw;
            }
            &:hover {
                svg {
                    background: $color-extra;
                    stroke: $color-main;
                }
                span {
                    margin-right: 20px;
                }
            }
        }
    }
}

@include mq($until: xl) {
    .hero {
        &:after {
            width: 300px;
        }
        &__bottom a {
            svg {
                width: 50px;
                height: 50px;
                margin-left: 65px;        
            }
        }
    }
}
@include mq($until: lg) {
    .hero {
        min-height: unset;        
        padding-right: 0;
        &::after {
            width: 190px;
        }
        &__slider .image {
            aspect-ratio: 4/3;
            min-height: unset;        
        }
        &__content {
            display: flex;
        }
        &__top {
            position: relative;
            padding: 50px;
            align-items: flex-start;
            justify-content: center;
            h1, h2 { 
                text-shadow: none;
            }
        }
        &__bottom {
            position: relative;
            padding: 0;
            background-color: $color-extra;
            width: 350px;
            a {
                position: relative;
                right: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                text-align: center;
                height: 100%;
                width: 100%;
                padding: 50px;
                justify-content: center;
                &:hover span {
                    margin: 0;
                }
                span {
                    color: $color-main;
                    text-shadow: none;
                    display: block;
                    margin-bottom: 20px;
                }
                svg {
                    border: 1px solid $color-main;
                    stroke: $color-main;
                    margin: 0;
                    line {
                        stroke-width: 3;
                    }
                }
            }
        }
    }
}
@include mq($until: md) {
    .hero {
        &:after {
            display: none;
        }
    }
}
@include mq($until: sm) {
    .hero {
        &__top {
            padding: 30px;
        }
        &__bottom a {
            padding: 30px;
            aspect-ratio: 1;
        }
    }
}
@include mq($until: xs) {
    .hero {
        &__top {
            padding: 20px;
            h1 {
                font-size: rem(38px);
            }
            h2 {
                margin-top: 10px;
            }
        }
        &__bottom {
            width: unset;
            a {
                aspect-ratio: unset;
                padding: 0;
                span {
                    display: none;
                }
                svg {
                    border: 0;
                }
            }
        }
    }
}
