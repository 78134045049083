.estate {
    background-color: #fff;
    &__homes {
        svg {
            width: 100%;
            height: auto;
            path {
                opacity: 0;
                transition: ease 0.3s opacity;
                fill: #4caf50;
                &:hover, &.active {
                    cursor: pointer;
                    opacity: 0.9;
                }
            }
        }
    }
    &__content {
        display: grid;
        grid-template-columns: 1fr 360px;
        grid-column-gap: 60px;
        padding-bottom: 130px;
    }
    &__credit {
        padding-top: 70px;
        padding-left: 60px;
        h2 {
            font-size: rem(32px);
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 40px;
        }
        p {
            line-height: rem(38px);
            margin: 40px 0;
        }
        a {
            background-color: #F7F7F7;
            color: #2D384A;
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            font-style: italic;
            font-weight: 500;
            transition: ease 0.3s all;
            &:hover {
                background-color: $color-extra;
            }
        }
        img {
            margin: 0 20px;
        }
    }
    .flat-list__tooltip {
        pointer-events: none;
        position: absolute;
        font-size: 18px;
        text-align: center;
        background: $color-extra;
        padding: 10px 15px;
        z-index: 5;
        height: 30px;
        line-height: 30px;
        margin: 0 auto;
        color: $color-main;
        border-radius: 5px;
        border: 1px solid #fff;
        transform: translateX(-50%);
        display: none;
        box-sizing: unset;
    
        &.active {
            display: block;
        }
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 100%;
            width: 0;
            height: 0;
            margin-left: -8px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid white;
          }
          &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 100%;
            width: 0;
            height: 0;
            margin-left: -10px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #eee;
          }
      
    }
}


@include mq($until: md){
    .estate {
        &__content {
            grid-template-columns: 1fr;
            padding-bottom: 80px;
            padding-right: 0;
        }
        &__credit {
            padding-top: 0;
            padding-left: 50px;
            padding-right: 50px;
            text-align: center;
            a {
                max-width: 260px;
                margin: 0 auto;            
            }
        }
    }
    .flat-list__text {
        padding-right: 50px;
    }
}
@include mq($until: sm){
    .estate {
        .flat-list__text {
            padding-right: 30px;
        }
        &__credit {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}
@include mq($until: xs){
    .estate {
        /*&__homes {
            overflow: hidden;
            height: 30vh;
            position: relative;
            svg {
                height: 30vh;
                width: auto;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 0;
            }
        }
        .flat-list__text {
            padding-right: 20px;
        }*/
        &__credit {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
