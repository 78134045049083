.about {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 130px;
    padding-bottom: 110px;
    position: relative;
    z-index: 1;
    &__content {
        max-width: 50%;
        width: 100%;
        h2 {
            font-size: rem(34px);
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 60px;
        }
        p {
            font-size: rem(18px);
            line-height: rem(38px);
        }
    }
    &__image {
        position: relative;
        img {
            height: auto;
        }
        &::after {
            content: '';
            width: 1px;
            height: 100vh;
            position: absolute;
            background-color: $color-extra;
            left: 0;
            bottom: 20%;
            z-index: -1;
        }
    }
}

@include mq($until: md) {
    .about {
        flex-direction: column;
        padding-top: 80px;
        padding-bottom: 80px;
        &__content {
            max-width: 100%;
            text-align: center;
            padding-right: 0;
        }
        &__image {
            max-width: 500px;
            margin-top: 80px;
            &:after {
                display: none;
            }
            img {
                transform: none!important;
            }
        }
    }
}
