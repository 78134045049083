.virtual {
    position: relative;
    margin-bottom: 350px;
    &:before {
        content: '';
        position: absolute;
        background: #2D384A;
        width: calc(8vw + 300px);
        height: 60vh;
        top: -10vh;
        right: 0;
        z-index: -1;
    }
    &:after {
        content: '';
        position: absolute;
        background: #E3D3B9;
        width: 50vw;
        height: 300px;
        bottom: -150px;
        left: 0;
        z-index: -1;
    }
    h2 {
        font-size: rem(26px);
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    .v-walk__box {
        height: 40vw;
        background-size: cover;
        background-position: center;
        position: relative;
        iframe {
            display: none;
        }
        .start {
            cursor: pointer;
            position:absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            background: $color-extra;
            padding: 10px 20px;
            transform: translateX(-50%) translateY(-50%);
            display: flex;
            align-items: center;
            white-space: nowrap;
            span {
                color: $color-main;
                font-size: rem(34px);
                font-weight: 700;
                text-transform: uppercase;
            }
            svg {
                margin-left: 20px;
                stroke: $color-main;
                width: 20px;
                height: 20px;
                line {
                    stroke-width: 4;
                }
            }
        }
    }
}

@include mq($until: xl){
    .virtual {
        &:before {
            width: 400px;
        }
    }
}

@include mq($until: lg){
    .virtual {
        &:before {
            width: 350px;
        }
        &:after {
            bottom: -100px;
        }
    }
}

@include mq($until: md){
    .virtual {
        margin-bottom: 80px;
        &:before {
            display: none;
        }
        &:after {
            height: 200px;
            bottom: -50px;
        }
        h2 {
            text-align: center;
        }
        .v-walk__box {
            height: 80vh;
            .start span {
                font-size: rem(20px);
            }
        }
    }
}

@include mq($until: xs){
    .virtual {
        margin-bottom: 80px;
        &:before {
            display: none;
        }
        &:after {
            height: 200px;
            bottom: -50px;
        }
        h2 {
            text-align: center;
        }
        .v-walk__box {
            height: 70vh;
            .start span {
                font-size: rem(20px);
            }
        }
    }

}
